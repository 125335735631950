import React from 'react'
import Layout from '../templates/Login'
import Form from '../modules/Form'

const IndexPage = () => {
    return (
        <Layout>
            <Form view='login'/>
        </Layout>
    )
}

export default IndexPage
